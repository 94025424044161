import { z } from 'zod';

import { ResourceStatus } from '__generated-api__';
import { FileImageRelationUpdateSchema, ImageSettings } from './file';

export const ResourceModelBase = z.object({
  id: z.number().nullable(),
  name: z.string().min(3).max(60),
  content: z.string().min(3),
  type: z.string().min(3).max(60),
  status: z.nativeEnum(ResourceStatus),
  image: FileImageRelationUpdateSchema,
  image_settings: ImageSettings.optional().nullable(),
  wistia_id: z.string().nullable().optional(),
  files: z
    .array(
      z.object({
        id: z.number(),
      })
    )
    .optional(),
  topics: z
    .array(
      z.object({
        id: z.number(),
        term: z.string().optional(),
      })
    )
    .optional(),
  companies: z
    .array(
      z.object({
        id: z.number(),
        name: z.string().optional(),
      })
    )
    .optional(),
});
export const ResourceModelUpdate = ResourceModelBase;
