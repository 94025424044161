import { ForbiddenError, subject } from '@casl/ability';
import merge from 'lodash/merge';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { z } from 'zod';

import { Resource, ResourceStatus } from '__generated-api__';
import api from 'api';
import { AuthStatus, useAbility, useAuth } from 'auth';
import axios from 'axios';
import classNames from 'classnames';
import EditorField from 'components/Form/EditorField';
import DisplayFieldErrors from 'components/Form/FieldError';
import Form, { FormProvider, useForm } from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import SelectField from 'components/Form/SelectField';
import SubmitButton from 'components/Form/SubmitButton';
import Icon from 'components/icon';
import { useMutation } from 'hooks/query';
import { pick } from 'lodash';
import { DisplayReactQueryError } from 'my-account/components/DisplayFieldError';
import { Modal } from 'my-account/components/Modal';
import { MultiFileField } from 'my-account/components/MultiFileUpload';
import BlockContentImage from 'my-account/components/SPB/BlockContentImage';
import { ChooseImagePlaceholder } from 'my-account/components/SPB/ImageBlockField/modal-contents/choose';
import EditImageModalContent from 'my-account/components/SPB/ImageBlockField/modal-contents/edit';
import MediaLibraryModalContent from 'my-account/components/SPB/ImageBlockField/modal-contents/library';
import { UploadImageProgress } from 'my-account/components/SPB/ImageBlockField/modal-contents/upload-progress';
import { SelectCompany } from 'my-account/components/SelectCompany';
import { SelectTopic } from 'my-account/components/SelectTopic';
import { useToast } from 'my-account/toast';
import { useImageUpload } from 'my-account/utils/image-upload';
import { ResourceModelBase } from 'my-account/validations/resource';
import { useController, useFormContext, useWatch } from 'react-hook-form';

const ResourceUpdateSchema = ResourceModelBase.omit({ id: true, status: true });

type ResourceDefaultValues = Partial<z.infer<typeof ResourceUpdateSchema>>;

const getResourceInitialValues = (resource: Resource | undefined): ResourceDefaultValues => {
  if (typeof resource !== 'undefined') {
    return {
      name: resource.name,
      content: resource.content,
      image: resource.image ?? undefined,
      image_settings: resource.image_settings ?? null,
      files: resource.files,
      type: resource.type,
      wistia_id: resource.wistia_id,
      topics: resource.topics
        .filter(Boolean)
        .filter((item) => item.id)
        .map((item) => ({ id: item.id || 0, term: item.term || '' })),
      companies: resource.companies
        .filter(Boolean)
        .filter((item) => item.id)
        .map((item) => ({ id: item.id || 0, name: item.name || '' })),
    };
  }

  return {
    name: '',
    content: '',
    files: [],
    type: 'document',
    topics: [],
    companies: [],
    wistia_id: '',
    image: undefined,
    image_settings: undefined,
  };
};

export const EditResourceForm: React.VFC<{ resource?: Resource }> = ({ resource }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const ability = useAbility();
  const context = useFormContext<ResourceDefaultValues>();
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<any>({});

  const [createResource] = useMutation(api.resource.createResource);
  const [updateResource] = useMutation(api.resource.updateResource);
  const [deleteResource] = useMutation(api.resource.deleteResource);
  const [modalState, setModalState] = React.useState<ResourceModalStateValue>('closed');

  const canSubmit = resource ? ability.can('update', subject('Resource', resource)) : ability.can('create', 'Resource');
  const canRemove = resource ? ability.can('delete', subject('Resource', resource)) : false;
  const auth = useAuth();
  if (auth.status !== AuthStatus.LoggedIn && auth.status !== AuthStatus.LoggingOut) {
    return null;
  }

  const onClose = () => {
    setModalState('closed');
  };
  return (
    <Form
      schema={ResourceUpdateSchema}
      onInvalidSubmit={(values, errors, event) => {
        setErrors(errors);
        toast.notify({
          type: 'error',
          title: 'Error',
          message: 'There are errors in the form. Please correct them and try again.',
        });
      }}
      onSubmit={async (values, ctx) => {
        ForbiddenError.from(ability).throwUnlessCan(
          resource ? 'update' : 'create',
          subject('Resource', merge({}, resource, values))
        );

        const data = {
          ...values,
          image: values.image ?? null,
          image_settings: values.image_settings ?? null,
          topics: values.topics,
        };
        if (typeof resource === 'undefined') {
          const res = await createResource([{ createResourceBody: data }]);
          ctx.reset(getResourceInitialValues(res.data));
          history.push(`/resources/${res.data.id}`);

          toast.notify({
            type: 'success',
            title: 'Success',
            message: "You've successfully created a new Resource.",
          });
        } else {
          const res = await updateResource([{ id: resource.id, updateResourceBody: data }]);
          ctx.reset(getResourceInitialValues(res.data));
          history.push(`/resources/${res.data.id}`);
          toast.notify({
            type: 'success',
            title: 'Success',
            message: "You've successfully updated the Resource.",
          });
        }
      }}
      initialValues={getResourceInitialValues(resource)}
      className="u-mb-0"
      hasFloatingLabels
      formProps={{ mode: 'onSubmit' }}
    >
      <section className="c-block c-block--spacing-t-extra-small c-block--spacing-b c-block--spacing-b-large@md">
        <div className="o-container-fluid">
          <div className="o-row">
            <div className="o-col-3@md">
              <p className="u-text-xs u-uppercase u-mb-spacer-base-small">General Information</p>
              <p className="c-note">Create an resource for the resource center.</p>
              <TopicSettings
                name="topics"
                addTopic={(topic) => {
                  let topics = context.getValues('topics');
                  if (!topics) topics = [];
                  context.setValue('topics', [...topics, topic]);
                }}
              />
            </div>

            <div className="o-col-9@md">
              <InputField name="name" label="Name" elStyle="fill" />
              <SelectTopic name="topics" label="Topic" elStyle="fill" isMulti />
              <SelectField name="type" label="Type" elStyle="fill">
                <option value="document">Document</option>
                <option value="scorm">SCORM</option>
              </SelectField>
              {console.log(context)}
              {context && context.getValues('type') === 'scorm' ? (
                <span> Upload scorm</span>
              ) : (
                <MultiFileField
                  name="files"
                  type="file"
                  onFileUpload={(files) => {
                    console.log('onFileUpload Files', files);
                    return setUploading(false);
                  }}
                  onUploadStart={() => {
                    console.log('onUploadStart');
                    setUploading(true);
                  }}
                />
              )}
            </div>
          </div>
          <hr />
          <div className="o-row">
            <div className="o-col-3@md">
              <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Access</p>
              <p className="c-note">
                Limit users who can access this. Choose the companies that should be able to see this. To allow all
                portal users to access this, leave this field empty.
              </p>
            </div>

            <div className="o-col-9@md">
              <SelectCompany name="companies" label="Company" elStyle="fill" isMulti={true} />
            </div>
          </div>
          <hr />
          <div className="o-row">
            <div className="o-col-3@md">
              <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Main Image</p>
              <p className="c-note">Attach a splash image to the top of the page.</p>
            </div>

            <div className="o-col-9@md">
              <ResourceImageField
                openImageLibrary={() => {
                  setModalState('image-library');
                }}
                openImageEdit={() => {
                  setModalState('image-edit');
                }}
              />
              {errors.image && (
                <ul className="c-form-element--error__list" style={{ display: 'block' }}>
                  <li>The image is required.</li>
                </ul>
              )}

              <Modal
                isOpen={modalState !== 'closed'}
                onRequestClose={onClose}
                style={{
                  content: {
                    maxWidth: modalState === 'image-library' ? '80rem' : '41.25rem',
                  },
                }}
              >
                <div
                  className={classNames('c-modal', { 'c-modal--dark c-color--invert': modalState === 'image-edit' })}
                  style={{ width: '100%' }}
                >
                  {/*eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
                  <a
                    href="#"
                    className="c-modal-close"
                    onClick={(event) => {
                      event.preventDefault();
                      onClose();
                    }}
                  />

                  <div className="c-modal__main">
                    {modalState === 'image-library' && (
                      <ResourceMediaLibraryModalStateContent title="" setModalState={setModalState} />
                    )}
                    {modalState === 'image-edit' && (
                      <ResourceImageEditModalStateContent title="" setModalState={setModalState} />
                    )}
                  </div>
                </div>
              </Modal>
            </div>
          </div>
          <hr />
          <div className="o-row">
            <div className="o-col-3@md">
              <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Wistia Video</p>
              <p className="c-note">Provide a Wistia video ID or an embed code to embed a video from Wistia.</p>
            </div>

            <div className="o-col-9@md">
              <InputField type="textarea" name="wistia_id" label="Wistia Video ID" elStyle="fill" />
            </div>
          </div>
          <hr />
          <div className="o-row">
            <div className="o-col-3@md">
              <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Description</p>
              <p className="c-note">Create an resource for the resource center.</p>
            </div>

            <div className="o-col-9@md">
              <EditorField
                name="content"
                label="Content"
                elStyle="fill"
                toolbar={['heading', 'bold', 'italic', 'link']}
              />
            </div>
          </div>

          <div className="o-row">
            <div className="o-col-12">
              <hr className="u-mt-spacer-base u-mt-spacer-base-large@sm u-mb-spacer-base-large u-mb-spacer-section-small@sm" />
            </div>
          </div>

          <div className="o-row u-items-center">
            <div className="o-col-4@md o-offset-3@md">
              <div className="c-form-footer">
                <SubmitButton variant="secondary" isFull disabled={!canSubmit || uploading}>
                  {uploading ? 'Uploading Files' : 'Save changes'}
                </SubmitButton>
              </div>
            </div>

            {canRemove && typeof resource !== 'undefined' && (
              <div className="o-col-5@md u-text-right@md">
                {resource.status === ResourceStatus.Active ? (
                  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                  <a
                    href="#"
                    className="c-link-cta-basic c-link-cta--small u-mt-spacer-base u-mt-0@md u-mb-0"
                    onClick={async (event) => {
                      event.preventDefault();

                      if (window.confirm(`Are you sure that you really want to archive "${resource.name}" resource?`)) {
                        await deleteResource([{ id: resource.id }]);

                        await Promise.all([
                          queryClient
                            .cancelQueries(api.resource.listResources.getQueryKey()[0])
                            .then(() => queryClient.invalidateQueries(api.resource.listResources.getQueryKey()[0])),
                          queryClient
                            .cancelQueries(api.resource.getResource.getQueryKey({ id: resource.id })[0])
                            .then(() =>
                              queryClient.invalidateQueries(
                                api.resource.getResource.getQueryKey({ id: resource.id })[0]
                              )
                            ),
                        ]);

                        history.push('/resources');
                        toast.notify({
                          type: 'success',
                          title: 'Success',
                          message: `The "${resource.name}" resource was archived successfully.`,
                        });
                      }
                    }}
                  >
                    <Icon name="trash" className="o-svg-icon o-svg-larger" />
                    <span>Archive resource</span>
                  </a>
                ) : (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    href="#"
                    className="c-link-cta-basic c-link-cta--small u-mt-spacer-base u-mt-0@md u-mb-0"
                    onClick={async (event) => {
                      event.preventDefault();

                      if (
                        window.confirm(`Are you sure that you really want to activate "${resource.name}" resource?`)
                      ) {
                        await updateResource([
                          {
                            id: resource.id,
                            updateResourceBody: {
                              name: resource.name,
                              status: ResourceStatus.Active,
                            },
                          },
                        ]);
                        await Promise.all([
                          queryClient
                            .cancelQueries(api.resource.listResources.getQueryKey()[0])
                            .then(() => queryClient.invalidateQueries(api.resource.listResources.getQueryKey()[0])),
                          queryClient
                            .cancelQueries(api.resource.getResource.getQueryKey({ id: resource.id })[0])
                            .then(() =>
                              queryClient.invalidateQueries(
                                api.resource.getResource.getQueryKey({ id: resource.id })[0]
                              )
                            ),
                        ]);
                        toast.notify({
                          type: 'success',
                          title: 'Success',
                          message: `The "${resource.name}" resource was activated successfully.`,
                        });
                      }
                    }}
                  >
                    <Icon name="export" className="o-svg-icon o-svg-larger" />
                    <span>Activate resource</span>
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    </Form>
  );
};

const ResourceImageField: React.VFC<{
  openImageLibrary: () => void;
  openImageEdit: () => void;
}> = ({ openImageLibrary, openImageEdit }) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext<ResourceDefaultValues>();

  const image = useWatch<ResourceDefaultValues, 'image'>({ name: 'image' });
  const imageSettings = useWatch<ResourceDefaultValues, 'image_settings'>({ name: 'image_settings' });

  const { getRootProps, getInputProps, isDragActive, open, uploadImageState, uploadProgress, cancelUpload } =
    useImageUpload({
      onChange: (data) => {
        setValue('image', data);
        setValue('image_settings', {
          left: 0,
          top: 0,
          rotate: 0,
          flip: 0,
          width: data.width,
          height: data.height,
        });
      },
    });

  return (
    <>
      <input {...getInputProps()} />

      {uploadImageState.isLoading ? (
        <UploadImageProgress title="" uploadProgress={uploadProgress} cancelUpload={cancelUpload} />
      ) : (
        <div {...getRootProps()}>
          {isDragActive ? (
            <div className="c-add-media c-add-media--active u-mb-spacer-base">
              <div>
                <Icon name="upload" className="o-svg-lg" />
              </div>

              <div>
                <button className="c-link-cta-basic c-link-cta--small" type="button">
                  <span>Drop file here...</span>
                </button>
              </div>
            </div>
          ) : typeof image !== 'undefined' ? (
            <BlockContentImage
              image={image}
              image_settings={imageSettings ?? undefined}
              openFileUpload={open}
              openImageLibrary={openImageLibrary}
              clearImage={() => {
                setValue('image', undefined);
                setValue('image_settings', undefined);
              }}
              openImageEdit={openImageEdit}
              hideImageDetails
            />
          ) : (
            <ChooseImagePlaceholder openLibrary={openImageLibrary} open={open} />
          )}
          {uploadImageState.isError && uploadImageState.error && !axios.isCancel(uploadImageState.error) && (
            <DisplayReactQueryError error={uploadImageState.error} />
          )}
          <DisplayFieldErrors error={pick(errors, 'image', 'image_settings')} hideKeyLabels />
        </div>
      )}

      <DisplayFieldErrors error={pick(errors, 'image', 'image_settings')} hideKeyLabels />
    </>
  );
};

const ResourceMediaLibraryModalStateContent: React.VFC<{
  title: string;
  setModalState: (value: ResourceModalStateValue) => void;
}> = ({ title, setModalState }) => {
  const { setValue } = useFormContext<ResourceDefaultValues>();

  return (
    <MediaLibraryModalContent
      title={title}
      onInsert={(image) => {
        setValue('image', image);
        setValue('image_settings', {
          top: 0,
          left: 0,
          rotate: 0,
          flip: 0,
          width: image.width,
          height: image.height,
        });
        setModalState('closed');
      }}
    />
  );
};
type ResourceModalStateValue = 'closed' | 'image-library' | 'image-edit';
const ResourceImageEditModalStateContent: React.VFC<{
  title: string;
  setModalState: (value: ResourceModalStateValue) => void;
}> = ({ title, setModalState }) => {
  const { setValue } = useFormContext<ResourceDefaultValues>();

  const image = useWatch<ResourceDefaultValues, 'image'>({ name: 'image' });
  const imageSettings = useWatch<ResourceDefaultValues, 'image_settings'>({ name: 'image_settings' });

  React.useEffect(() => {
    // It should not happen that we are in the image-edit modal state without image in the content
    // or in the settings, but anyway adding this just in case
    if (!image) {
      setModalState('closed');
    }
  }, [setModalState, image]);

  if (image) {
    return (
      <EditImageModalContent
        title={title}
        image={image}
        settings={imageSettings ?? undefined}
        onChange={(settings) => {
          setValue('image_settings', settings);
          setModalState('closed');
        }}
        onCancel={() => {
          setModalState('closed');
        }}
      />
    );
  }

  return null;
};

const TopicSettings: React.VFC<{
  name: string;
  addTopic: (topic: { id: number; term: string }) => void;
}> = ({ name, addTopic }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { field } = useController({ name });
  const { context, formProps } = useForm({
    initialValues: {
      term: '',
    },
    schema: z.object({
      term: z.string().nonempty(),
    }),
    onSubmit: async (values) => {
      api.resourceCategory.createResourceCategory({ createCategoryBody: { term: values.term } }).then((res) => {
        field.onChange([...field.value, { id: res.data.id ?? 0, term: res.data.term ?? '' }]);
        setIsModalOpen(false);
        context.reset({ term: '' });
      });
    },
    stopSubmitPropagation: true,
    hasFloatingLabels: true,
  });

  const onClose = () => {
    if (context.formState.isDirty) {
      if (window.confirm('Do you really want to leave? You have unsaved changes!')) {
        setIsModalOpen(false);
        context.reset({ term: '' });
      }
    } else {
      setIsModalOpen(false);
      context.reset({ term: '' });
    }
  };

  return (
    <>
      <>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="c-link-cta-basic u-mt-spacer-base-small u-mb-spacer-base"
          onClick={(event) => {
            event.preventDefault();
            setIsModalOpen(true);
          }}
        >
          <span>Add New Topic</span>
          <Icon name="add" className="o-svg-icon u-text-neutral-300" />
        </a>
      </>
      <Modal isOpen={isModalOpen} onRequestClose={onClose} style={{ content: { maxWidth: '41.25rem' } }}>
        <div className="c-modal" style={{ width: '100%' }}>
          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
          <a
            href="#"
            className="c-modal-close"
            onClick={(event) => {
              event.preventDefault();
              onClose();
            }}
          />

          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-small c-block--spacing-b-small">
              <div className="o-container-fluid">
                <div className="o-row">
                  <div className="o-col-12">
                    <div className="c-block__header c-block__header--hor">
                      <div className="c-block__header-content u-items-center u-block u-flex@sm">
                        <div>
                          <h4 className="u-mb-spacer-base-large">Add Topic</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="o-row">
                  <div className="o-col-12">
                    <FormProvider {...context}>
                      <form {...formProps}>
                        <InputField name="term" label="Term" elStyle="fill" />
                        <SubmitButton>Save</SubmitButton>
                      </form>
                    </FormProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
