import { subject } from '@casl/ability';
import { WistiaPlayer } from '@wistia/wistia-player-react';
import { FileRelation } from '__generated-api__';
import api from 'api';
import { useAbility, useAuthenticatedUser } from 'auth';
import Icon from 'components/icon';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useMutation, useQuery } from 'hooks/query';
import useDocumentTitle from 'hooks/useDocumentTitle';
import CroppedImageBackground from 'my-account/components/CroppedImageBackground';
import { MainHero } from 'my-account/components/MainHero';
import { Block403Error } from 'my-account/pages/403page';
import { useToast } from 'my-account/toast';
import { ErrorMessagesContent } from 'my-account/utils/error-handler';
import { calcReadTime } from 'my-account/utils/text';
import { getUserName } from 'my-account/utils/user';
import ReactMarkdown from 'react-markdown';
import { Link, useParams } from 'react-router-dom';
import { getAuthData } from 'api/auth';
import Cookies from 'js-cookie';
import { useState } from 'react';

export default function ResourceViewPage() {
  const { id } = useParams<{ id?: string }>();
  const ability = useAbility();
  const [resourceRes, getResourceState] = useQuery(api.resource.getResource, { id: Number(id) });
  const [deleteResource] = useMutation(api.resource.deleteResource);
  const toast = useToast();
  useDocumentTitle(resourceRes?.data.name);
  let canUpdate = false;
  if (resourceRes?.data) {
    canUpdate = ability.can('update', subject('Resource', resourceRes?.data));
  }

  let [restartScorm, setRestartScorm] = useState(false);

  const scormId = resourceRes?.data?.scorm?.id;
  const [scormProgress] = useQuery(api.scorm.getProgress, { id: scormId ?? 0 });

  const authData = getAuthData();
  const user = useAuthenticatedUser();
  const userScormProgressInfo = authData
    ? {
        apiURL: process.env.REACT_APP_API_BASE_URL,
        token: authData?.accessToken,
        user_id: user.id.toString(),
        user_name: user.first_name + ' ' + user.last_name,
        lesson_id: resourceRes?.data?.scorm?.id.toString() ?? '0',
        suspend_data: btoa(scormProgress?.data?.suspend_data ?? ''),
      }
    : undefined;

  let setScormCookie = (restart: boolean) => {
    if (userScormProgressInfo) {
      if (restart) {
        userScormProgressInfo.suspend_data = '';
      }
      var cookieString = JSON.stringify(userScormProgressInfo);
      Cookies.set('scormData', cookieString, {
        sameSite: 'None',
        secure: true,
        domain: window.location.hostname.replace('www.', ''),
      });
      return true;
    }
    return false;
  };

  // const saveOrigins = ['https://dtqexr3jwva7q.cloudfront.net'];
  // useEffect(() => {
  //   const listenForScormAPI = () => {
  //     window.addEventListener(
  //       'message',
  //       (event) => {
  //         if (saveOrigins[0].includes(event.origin)) {
  //           const data = event.data;
  //           if (data.method === 'LMSGetValue') {
  //             return 'brad';
  //             // api.resource.getScormValue({ id: resourceRes.data.id, key: data.key }).then((res) => {
  //             //   event.source.postMessage({ method: 'LMSGetValue', key: data.key, value: res.data }, event.origin);
  //             // }
  //           }
  //           if (data.method === 'LMSSetValue') {
  //             return 'brad';
  //             // api.resource.setScormValue({ id: resourceRes.data.id, key: data.key, value: data.value }).then((res) => {
  //             //   event.source.postMessage({ method: 'LMSSetValue', key: data.key, value: data.value }, event.origin);
  //             // }
  //           }
  //         }
  //       },
  //       false
  //     );
  //   };
  //   listenForScormAPI();
  // }, []);

  return (
    <>
      <MainHero />

      <section className="c-block c-block--spacing-t-extra-small c-block--spacing-t-small@lg c-block--spacing-b-extra-small c-block--spacing-b-small@lg c-block--bg-light2 c-block--hero c-block--columns@lg">
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow">
            <div className="o-col-6@lg">
              <div className="c-hero__content u-justify-around">
                <div className="c-block__header c-block__header--large">
                  <p className="c-headline">
                    <Link to="/resources">Symmons Resource Center</Link>
                  </p>
                  <h1 className="c-title--extra-large">{resourceRes?.data?.name}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {resourceRes?.data?.image && resourceRes?.data?.image_settings && (
          <CroppedImageBackground image={resourceRes?.data?.image} settings={resourceRes?.data?.image_settings} />
        )}
        <div className="c-block__overlay c-block__overlay--hero c-block__overlay--base-half c-block__overlay--opacity-100 u-ml-gutter u-hidden u-block@lg"></div>
        <div className="c-block__pattern c-block__pattern--light3 c-block__overlay--opacity-40"></div>
      </section>

      {getResourceState.isLoading && (
        <div className="c-listing__none">
          <div className="c-listing__none-spinner"></div>
          <p className="c-listing__none-title">Loading...</p>
        </div>
      )}
      {getResourceState.isError && (
        <div className="o-container-fluid u-pb-spacer-section">
          <div className="o-row u-flex-grow u-items-center">
            <div className="o-col-12">
              <div className="c-listing__none">
                <div className="c-listing__none-figure">
                  <Icon name="error" className="o-svg-icon" />
                </div>
                <p className="c-listing__none-title">Error</p>
                <ErrorMessagesContent error={getResourceState.error} />
              </div>
            </div>
          </div>
        </div>
      )}
      {typeof resourceRes !== 'undefined' && getResourceState.isSuccess && (
        <>
          {ability.can('read', subject('Resource', resourceRes.data)) ? (
            <section className="c-block c-block--spacing-t-extra-small c-block--spacing-t@lg c-block--spacing-b-small c-block--spacing-b@lg c-block--divider-internal">
              <div className="o-container-fluid">
                {resourceRes?.data?.type === 'scorm' ? (
                  <div className="o-row c-article__wrapper">
                    {resourceRes?.data?.scorm &&
                    resourceRes?.data?.scorm_user_progress &&
                    resourceRes?.data?.scorm_user_progress.lesson_status === 'passed' &&
                    !restartScorm ? (
                      <div className="c-listing__none">
                        <div className="c-listing__none-figure">
                          <Icon name="checkmark" className="o-svg-icon" />
                        </div>
                        <p className="c-listing__none-title">Completed</p>
                        <p className="c-listing__none-figure">
                          You have completed this course with score of&nbsp;
                          <strong>
                            {resourceRes?.data?.scorm_user_progress.score_raw}/
                            {resourceRes?.data?.scorm_user_progress.score_max}
                          </strong>
                          .
                        </p>
                        <Link
                          to={`/resources/${resourceRes.data.id}/scorm`}
                          onClick={(event) => {
                            setRestartScorm(true);
                            event.preventDefault();
                          }}
                          className="c-link-cta-basic c-link-cta--small"
                        >
                          Star over
                        </Link>
                      </div>
                    ) : resourceRes?.data.scorm && setScormCookie(restartScorm) ? (
                      <iframe
                        title={resourceRes?.data.name}
                        // @ts-ignore
                        src={resourceRes?.data.scorm.url}
                        width="100%"
                        height="800"
                        frameBorder="0"
                      />
                    ) : (
                      <div className="c-listing__none">
                        <div className="c-listing__none-figure">
                          <Icon name="error" className="o-svg-icon" />
                        </div>
                        <p className="c-listing__none-title">Error</p>
                        <p className="c-listing__none-title">No SCORM file found</p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="o-row c-article__wrapper">
                    <div className="o-col c-article__container">
                      <div className="o-row c-article__content-wrapper">
                        <div className="o-col c-article__info">
                          <div className="c-widget">
                            <p className="c-article__reading">{calcReadTime(resourceRes?.data.content)} minutes read</p>
                          </div>

                          <div className="c-widget">
                            <p>
                              Published on <br />
                              <strong>{format(parseISO(resourceRes?.data?.created_at ?? ''), 'MMM d, y')}</strong>
                            </p>
                          </div>

                          <div className="c-widget c-article-widget__author">
                            <span>
                              <img
                                alt="Symmons Industries"
                                src="https://secure.gravatar.com/avatar/52da90c4717477846b5b6e263cee256f?s=150&amp;d=mm&amp;r=g"
                                className="avatar avatar-150 photo c-article-widget__author-avatar"
                                height="150"
                                width="150"
                                loading="lazy"
                                decoding="async"
                              />{' '}
                            </span>
                            {resourceRes?.data.user && (
                              <p>
                                Author <br />
                                <strong>{getUserName(resourceRes?.data.user).name}</strong>
                              </p>
                            )}
                          </div>
                          {resourceRes?.data?.topics && resourceRes?.data?.topics.length > 0 && (
                            <div className="c-widget">
                              <p>Topics</p>
                              <ul className="c-article-widget__list">
                                {resourceRes?.data?.topics?.map((topic) => (
                                  <li key={topic.id}>
                                    <Link to={`/resources?topic=${topic.id}`}>{topic.term}</Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                          {resourceRes?.data?.companies && resourceRes?.data?.companies.length > 0 && (
                            <div className="c-widget">
                              <p>Who can see this</p>
                              <ul className="c-article-widget__list">
                                {resourceRes?.data?.companies?.map((company) => (
                                  <li key={company.id}>
                                    <Link to={`/resources?company=${company.id}`}>{company.name}</Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                          <div className="u-flex-grow"></div>
                        </div>

                        <div className="o-col c-article__content js-article-body">
                          {resourceRes?.data?.wistia_id && (
                            <div className="o-row u-mb-spacer-base-large">
                              <div className="o-col-12">
                                <WistiaPlayer mediaId={resourceRes?.data?.wistia_id} />
                              </div>
                            </div>
                          )}
                          <div className="js-reading-wrapper">
                            <article>
                              <div className="js-reading-content u-mb-spacer-base-large">
                                <div className="c-wysiwyg">
                                  <ReactMarkdown
                                    allowedElements={[
                                      'h1',
                                      'h2',
                                      'h3',
                                      'h4',
                                      'h5',
                                      'p',
                                      'em',
                                      'a',
                                      'strong',
                                      'br',
                                      'text',
                                    ]}
                                    skipHtml={true}
                                    unwrapDisallowed={true}
                                    components={{
                                      h1({ children }) {
                                        return <h1>{children}</h1>;
                                      },
                                      h2({ children }) {
                                        return <h2>{children}</h2>;
                                      },
                                      h3({ children }) {
                                        return <h3>{children}</h3>;
                                      },
                                      h4({ children }) {
                                        return <h4>{children}</h4>;
                                      },
                                      h5({ children }) {
                                        return <h5>{children}</h5>;
                                      },
                                      br() {
                                        return <br />;
                                      },
                                      a({ href, children }) {
                                        return (
                                          <a href={href ?? '#'} style={{ textDecoration: 'none' }}>
                                            {children}
                                          </a>
                                        );
                                      },
                                      text({ children }) {
                                        return <p>{children}</p>;
                                      },
                                      p({ children }) {
                                        return <p>{children}</p>;
                                      },
                                    }}
                                    children={resourceRes?.data.content}
                                  />
                                </div>
                              </div>
                              <footer className="c-article__footer"></footer>
                            </article>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="c-sidebar__item">
                      <div className="c-callout c-callout--bg-light js-sticky-widget">
                        <div className="o-row c-callout__container">
                          {canUpdate && (
                            <div className="o-col-12 c-callout__item">
                              <div className="c-callout__body">
                                <p className="c-callout__headline u-mb-spacer-base-small">Actions</p>
                                <p>
                                  <Link
                                    to={`/resources/${resourceRes.data.id}/edit`}
                                    className="c-link-cta-basic c-link-cta--small"
                                  >
                                    <Icon name="pencil" className="o-svg-icon o-svg-right" />
                                    <span>Edit resource</span>
                                  </Link>
                                </p>
                                <p>
                                  <Link
                                    to="/my-account/resources/add"
                                    className="c-link-cta-basic c-link-cta--small"
                                    onClick={async (event) => {
                                      event.preventDefault();
                                      if (
                                        window.confirm(
                                          `Are you sure that you really want to archive "${resourceRes.data.name}"?`
                                        )
                                      ) {
                                        await deleteResource([{ id: resourceRes.data.id }]);
                                        toast.notify({
                                          type: 'success',
                                          title: 'Success',
                                          message: `"${resourceRes.data.name}" was archived successfully.`,
                                        });
                                      }
                                    }}
                                  >
                                    <Icon name="trash" className="o-svg-icon o-svg-right" />
                                    <span>Delete resource</span>
                                  </Link>
                                </p>
                              </div>
                            </div>
                          )}
                          {resourceRes.data.files.length > 0 && (
                            <div className="o-col-12 c-callout__item">
                              <div className="c-callout__body">
                                <p className="c-callout__headline u-mb-spacer-base-small">Download Files</p>

                                <div className="c-chat__tag-list">
                                  {resourceRes.data.files.map((file: FileRelation) => (
                                    <a
                                      href={file.private_url}
                                      className="c-tag"
                                      target="_blank"
                                      rel="noreferrer"
                                      key={file.id}
                                    >
                                      <Icon name="download" />
                                      <span>{file.name}</span>
                                    </a>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          ) : (
            <Block403Error />
          )}
        </>
      )}
    </>
  );
}
