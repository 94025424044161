import { subject } from '@casl/ability';

import { SalesRepUserRoleEnum, UserRole } from '__generated-api__';
import api from 'api';
import { useAbility, useAuthenticatedUser } from 'auth';
import { useQuery } from 'hooks/query';
import { SidebarProfilePreview } from 'my-account/components/SidebarProfilePreview';
import { AdminCard } from 'my-account/components/AdminCard';
import { DashboardHeader } from 'my-account/components/DashboardHeader';

export default function AdminDashboardPage() {
  const user = useAuthenticatedUser();
  const ability = useAbility();
  const [companies, companiesQuery] = useQuery(api.company.listCompanies, {
    page: 1,
    perPage: 6,
    sort: undefined,
    direction: 'asc',
    search: undefined,
  });

  const [representatives, representativesQuery] = useQuery(api.user.listUsers, {
    page: 1,
    perPage: 6,
    sort: undefined,
    direction: 'asc',
    search: undefined,
    role: UserRole.SalesRep,
  });

  const [quotes, quotesQuery] = useQuery(api.quote.listQuotes, {
    page: 1,
    perPage: 6,
    sort: undefined,
    direction: 'asc',
    search: undefined,
  });

  const [userProjects, userProjectsQuery] = useQuery(api.project.listProjects, {
    page: 1,
    perPage: 6,
    sort: undefined,
    direction: 'asc',
    search: undefined,
    userId: user.id,
  });

  const [scorms, scormsQuery] = useQuery(api.scorm.listScorms, {
    page: 1,
    perPage: 6,
    sort: undefined,
    direction: 'asc',
    search: undefined,
  });

  const canListCompanies = ability.can('list', 'Company');
  const canCreateCompany = ability.can('create', 'Company');
  const canListLocations = ability.can('list', 'Location');
  const canListQuotes = ability.can('list', 'Quote');
  const canCreateQuote = ability.can('create', 'Quote');
  const canListProjects = ability.can('list', 'Project');
  const canCreateProject = ability.can('create', 'Project');
  const canListUsers = ability.can('list', 'User');
  const canListRepresentatives = ability.can('list', subject('User', { role: SalesRepUserRoleEnum.SalesRep }));
  const canCreateRepresentative = ability.can(
    'create',
    subject('User', { company_id: user.company_id, location_id: user.location_id, role: SalesRepUserRoleEnum.SalesRep })
  );
  const canListScorms = ability.can('list', 'Scorm');
  const canCreateScorm = ability.can('create', 'Scorm');

  return (
    <>
      <DashboardHeader />

      <section className="c-block c-block--spacing-t-small c-block--spacing-t@md c-block--spacing-b c-block--spacing-b-large@md">
        <div className="o-container-fluid">
          <div className="o-row">
            <div className="o-col-7@lg">
              {canListUsers && <AdminCard.Users />}
              {canListCompanies && (
                <AdminCard
                  count={companies && companiesQuery.isSuccess ? companies.data.total : undefined}
                  icon="office"
                  label={['Company', 'Companies']}
                  listingUrl="/companies"
                  addNewUrl={canCreateCompany ? '/companies/add' : undefined}
                />
              )}
              {canListLocations && <AdminCard.Locations />}

              {canListRepresentatives && (
                <AdminCard
                  count={representatives && representativesQuery.isSuccess ? representatives.data.total : undefined}
                  icon="chat"
                  label={['Representative', 'Representatives']}
                  listingUrl={`/users?role=${UserRole.SalesRep}`}
                  addNewUrl={canCreateRepresentative ? `/users/add?role=${UserRole.SalesRep}` : undefined}
                />
              )}
              {canListQuotes && (
                <AdminCard
                  count={quotes && quotesQuery.isSuccess ? quotes.data.total : undefined}
                  icon="package"
                  label={['Quote', 'Quotes']}
                  listingUrl="/quotes"
                  addNewUrl={canCreateQuote ? '/quotes/add' : undefined}
                />
              )}
              {canListProjects && (
                <AdminCard
                  count={userProjects && userProjectsQuery.isSuccess ? userProjects.data.total : undefined}
                  icon="book"
                  label={['Specification Package', 'Specification Packages']}
                  listingUrl={
                    '/specification-packages' + (user.role === SalesRepUserRoleEnum.SalesRep ? '?mine=1' : '')
                  }
                  addNewUrl={canCreateProject ? '/specification-packages/add' : undefined}
                />
              )}
              {canListScorms && (
                <AdminCard
                  count={scorms && scormsQuery.isSuccess ? scorms.data.total : undefined}
                  icon="book"
                  label={['Scorm Resource', 'Scorm Resources']}
                  listingUrl={'/scorms'}
                  addNewUrl={canCreateScorm ? '/resources/add' : undefined}
                />
              )}
            </div>
            <div className="o-col-5@lg">
              <div className="c-sidebar c-sidebar--has-divider u-pl-gutter@lg">
                <SidebarProfilePreview className="u-mb-spacer-base@lg" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
